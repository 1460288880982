@tailwind components;
@tailwind base;
@tailwind utilities;

body,
html {
  height: 100%;
  width: 100%;
  margin: 0;
}

.cl-navbar .cl-organizationPreview {
  display: none;
}

/*
  Configures mux player to solely show a mute button, when
  https://docs.mux.com/guides/player-customize-look-and-feel#style-with-css
*/
mux-player.mute-control-only {
  --media-object-fit: cover;
  --media-object-position: center;

  /* controls */
  /* --mute-button: none; */
  --play-button: none;
  --live-button: none;
  --seek-backward-button: none;
  --seek-forward-button: none;
  --captions-button: none;
  --airplay-button: none;
  --pip-button: none;
  --fullscreen-button: none;
  --cast-button: none;
  --playback-rate-button: none;
  --volume-range: none;
  --time-range: none;
  --time-display: none;
  --duration-display: none;
  --rendition-selectmenu: none;
  --bottom-play-button: none;
}

/*
  Styles control bar to be the size of the volume button, and overrides hover background to not be pink.

  This only works with the default mux theme because minimal and microvideo types have css issues when trying to only show the volume button.
  https://docs.mux.com/guides/player-themes
*/
mux-player.mute-control-only::part(control-bar) {
  width: 40px;
  align-self: flex-end;
}
mux-player.mute-control-only::part(bottom button):hover {
  background-color: #444;
}
